import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  Assessment,
  BusinessCenter,
  ExpandMore,
  LocationOn,
  Person,
} from '@mui/icons-material';
import {
  Collapse,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { endOfMonth, format, parse, parseISO, startOfMonth } from 'date-fns';

import Content from '../../../../../../components/layouts/content';
import { Slot } from '../../../../../../contexts/slot';
import { cpfFormat } from '../../../../../../helpers/document';
import { numberFormat } from '../../../../../../helpers/number';
import { phoneFormat } from '../../../../../../helpers/phone';
import resources from '../../../../../../resources';
import { IHerdCompositionOverviewResponse } from '../../../../../../resources/animal';
import { IProperty } from '../../../../../../resources/property';
import { IQuality } from '../../../../../../resources/quality';
import { IUserData } from '../../../../../../resources/user';
import { HerdCompositionChart } from '../../../../../dashboard/components/herd-composition-chart';
import MilkCompositionChart from '../../../../../dashboard/components/milk-composition-chart';
import HeaderForm from '../../../../components/header-form';
import UserDetailNavbarFilter from '../../../../components/user-detail-navbar-filter';
// import { IUserItem } from '../user-list';

interface IText {
  title: ReactNode;
  value: ReactNode;
}

const Text: React.FC<IText> = ({ title, value }) => (
  <Stack
    direction="column"
    justifyContent="space-between"
  >
    <Typography
      sx={{
        color: 'grey.500',
        fontSize: 14,
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 'bold',
      }}
    >
      {value ?? '-'}
    </Typography>
  </Stack>
);

const EmptyChart = () => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="center"
    spacing={1}
  >
    <Assessment sx={{ color: 'grey.300', fontSize: 80 }} />
    <Stack>
      <Typography sx={{ color: 'grey.500', fontSize: 18 }}>Ops!</Typography>
      <Typography sx={{ color: 'grey.500', fontSize: 14 }}>
        Nenhum dado para exibir
      </Typography>
    </Stack>
  </Stack>
);

const UserProviderDetail: React.FC = () => {
  const { cpf } = useParams<{ cpf: string }>();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingVolume, setLoadingVolume] = useState<boolean>(false);
  const [loadingPrice, setLoadingPrice] = useState<boolean>(false);
  const [loadingQuality, setLoadingQuality] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<string | null>(null);

  const [dataGraph, setDataGraph] = useState<any | null>(null);
  const [dataPie, setDataPie] = useState<any | null>(null);

  const [user, setUser] = useState<IUserData>();
  const [properties, setProperties] = useState<IProperty[]>([]);

  const [dataComposition, setDataComposition] = useState<any | null>(null);

  const [volume, setVolume] = useState<number>(0);
  const [price, setPrice] = useState<{ total: number; lastResult: boolean }>({
    lastResult: false,
    total: 0,
  });
  const [herdComposition, setHerdComposition] =
    useState<IHerdCompositionOverviewResponse>();

  const [quality, setQuality] = useState<IQuality>({
    cbt: 0,
    ccs: 0,
    esd: 0,
    est: 0,
    fat: 0,
    prot: 0,
  });

  const filterParams = useMemo(
    () => ({
      toDate: searchParams.has('toDate')
        ? parseISO(searchParams.get('toDate')!)
        : endOfMonth(new Date()),
      fromDate: searchParams.has('fromDate')
        ? parseISO(searchParams.get('fromDate')!)
        : startOfMonth(new Date()),
      centers: searchParams.getAll('centers') ?? [],
    }),
    [searchParams]
  );

  const getParams = (query: {
    fromDate: Date | null;
    toDate: Date | null;
    centers: string[] | null;
  }) => ({
    fromDate: query.fromDate
      ? format(new Date(query.fromDate), 'yyyy-MM-dd')
      : format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: query.toDate
      ? format(new Date(query.toDate), 'yyyy-MM-dd')
      : format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    codCenters: query.centers ?? [],
  });

  const currentIdentifierProducer = useMemo(
    () => properties.find((property) => property.id === expanded)?.code ?? null,
    [expanded, properties]
  );

  const fetchVolume = useCallback(async () => {
    const params = getParams(filterParams);

    if (!currentIdentifierProducer) {
      setVolume(0);
      return;
    }

    setLoadingVolume(true);
    const response = await resources.use('volume').getVolumeByProducer({
      identity: currentIdentifierProducer,
      fromDate: params.fromDate,
      toDate: params.toDate,
    });
    setLoadingVolume(false);

    if (response.status === 'OK') {
      setVolume(response.payload?.totalVolume ?? 0);
    } else {
      setVolume(0);
    }
  }, [currentIdentifierProducer, filterParams]);

  const fetchPrice = useCallback(async () => {
    const params = getParams(filterParams);

    if (!currentIdentifierProducer) {
      setPrice({
        lastResult: false,
        total: 0,
      });
      return;
    }
    setLoadingPrice(true);
    const response = await resources.use('price').getPrice({
      identity: currentIdentifierProducer,
      fromDate: params.fromDate,
      toDate: params.toDate,
    });
    setLoadingPrice(false);

    if (response.status === 'OK') {
      setPrice({
        lastResult: response.payload?.lastResult ?? false,
        total: response.payload?.price ?? 0,
      });
    } else {
      setPrice({ lastResult: false, total: 0 });
    }
  }, [currentIdentifierProducer, filterParams]);

  const fetchLastResultQuality = useCallback(async () => {
    // const params = getParams(filterParams);

    if (!currentIdentifierProducer) {
      setQuality({
        cbt: 0,
        ccs: 0,
        esd: 0,
        est: 0,
        fat: 0,
        prot: 0,
      });
      return;
    }

    setLoadingQuality(true);
    const response = await resources.use('quality').getLastResultQuality({
      identity: currentIdentifierProducer,
    });
    setLoadingQuality(false);

    if (response.status === 'OK') {
      setQuality((old) => {
        if (response.payload) {
          return response.payload;
        }
        return old;
      });
    }
  }, [currentIdentifierProducer]);

  const fetchUser = useCallback(async () => {
    const params = getParams(filterParams);
    let { codCenters } = params ?? [];
    if (codCenters.length === 0) {
      const { payload } = await resources.use('property').getCenters();
      codCenters = payload?.map((c) => c.codCenter) ?? [];
    }
    const response = await resources.use('user').userDataByCpf({
      cpf: cpf!,
      codCenters,
    });

    if (response.status === 'OK') {
      setUser(response.payload);
    }
  }, [cpf, filterParams]);

  const fetchProperties = useCallback(async () => {
    const response = await resources.use('property').getPropertiesByCpf({
      cpf: cpf!,
    });

    if (response.status === 'OK') {
      setProperties(response?.payload ?? []);
      const firstProperty = response.payload?.[0];
      if (firstProperty) {
        setExpanded(firstProperty.id);
      }
    }
  }, [cpf]);

  const fetchDataGraph = useCallback(async () => {
    const centers = searchParams.has('centers')
      ? searchParams.getAll('centers')
      : [];

    const startDate = searchParams.has('fromDate')
      ? new Date(searchParams.get('fromDate')!)
      : startOfMonth(new Date());

    const endDate = searchParams.has('toDate')
      ? new Date(searchParams.get('toDate')!)
      : endOfMonth(new Date());

    const response = await resources.use('volume').getVolumeByMilkComposition({
      codCenter: centers.join(','),
      fromDate: format(startDate, 'yyyy-MM-dd'),
      toDate: format(endDate, 'yyyy-MM-dd'),
      codePiracanjuba: currentIdentifierProducer ?? '',
    });

    if (response.status === 'OK') {
      setDataGraph(response?.payload?.data);

      if (response?.payload?.data) {
        const dataLocal: any = response?.payload?.data;

        setDataPie([
          {
            name: `litros para Amamentação`,
            value: Number(dataLocal?.calves?.value ?? 0),
          },
          {
            name: 'litros de Colostro',
            value: Number(dataLocal?.colostrum?.value ?? 0),
          },
          {
            name: 'litros Descarte',
            value: Number(dataLocal?.discardedMilk?.value ?? 0),
          },
          {
            name: 'litros em Uso Pessoal',
            value: Number(dataLocal?.internalConsumption?.value ?? 0),
          },
          {
            name: 'litros Outros Destinos',
            value: Number(dataLocal?.otherDestination?.value ?? 0),
          },
          {
            name: 'litros Vendidos',
            value: Number(dataLocal?.piracanjubaSale?.value ?? 0),
          },
        ]);
      } else {
        setDataPie(null);
      }
    } else {
      setDataGraph(null);
      setDataPie(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIdentifierProducer, filterParams]);

  const fetchHerdComposition = useCallback(async () => {
    const params = getParams(filterParams);

    if (!currentIdentifierProducer) {
      setHerdComposition(undefined);
      return;
    }

    const response = await resources.use('animal').getHerdCompositionOverview({
      toDate: params.toDate,
      fromDate: params.fromDate,
      codePiracanjuba: currentIdentifierProducer,
    });

    if (response.status === 'OK') {
      setHerdComposition(response.payload);
    } else {
      setHerdComposition(undefined);
    }
  }, [currentIdentifierProducer, filterParams]);

  const periodDescription = useMemo(() => {
    if (filterParams.fromDate && filterParams.toDate) {
      return `${format(filterParams.fromDate, 'dd/MM/yyyy')} à ${format(
        filterParams.toDate,
        'dd/MM/yyyy'
      )} `;
    }
    if (filterParams.fromDate) {
      return `De ${format(filterParams.fromDate, 'dd/MM/yyyy')}`;
    }
    if (filterParams.toDate) {
      return `Até ${format(filterParams.toDate, 'dd/MM/yyyy')}`;
    }
    return null;
  }, [filterParams.fromDate, filterParams.toDate]);

  useEffect(() => {
    fetchDataGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIdentifierProducer, filterParams, searchParams]);

  useEffect(() => {
    fetchPrice();
  }, [currentIdentifierProducer, fetchPrice]);

  useEffect(() => {
    fetchVolume();
  }, [currentIdentifierProducer, fetchVolume]);

  useEffect(() => {
    fetchLastResultQuality();
  }, [currentIdentifierProducer, fetchLastResultQuality]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchUser(), fetchProperties()]).finally(() => {
      setLoading(false);
    });
  }, [fetchProperties, fetchUser]);

  useEffect(() => {
    fetchHerdComposition();
  }, [fetchHerdComposition]);

  return (
    <>
      <Slot name="navbar">
        <UserDetailNavbarFilter
          periodFilterProps={{
            defaultValue: {
              fromDate: filterParams.fromDate,
              toDate: filterParams.toDate,
            },
          }}
        />
      </Slot>
      <Content
        backButton="./../.."
        title={<HeaderForm title="Fornecedor" />}
      >
        <Stack
          direction="column"
          spacing={1}
        >
          <Grid
            alignItems="flex-start"
            container
            spacing={2}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper sx={{ p: 4 }}>
                <Stack
                  direction="column"
                  spacing={3}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        color: 'success.main',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Person sx={{ mr: 1 }} /> Perfil do Usuário
                    </Typography>
                    <Typography
                      sx={{
                        color: 'success.main',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          width={50}
                        />
                      ) : user?.userData.isActive ? (
                        'Ativo'
                      ) : (
                        'Inativo'
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        color: 'grey.700',
                        fontSize: 22,
                        fontWeight: 'bold',
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          height={40}
                          sx={{ width: 1 / 2 }}
                        />
                      ) : (
                        user?.userData.name
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'grey.700',
                        fontSize: 12,
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          sx={{
                            width: 1 / 1.5,
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                        />
                      ) : (
                        <>
                          Código do Produtor:{' '}
                          <strong>{user?.userData.code}</strong>
                        </>
                      )}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack
                    direction="column"
                    spacing={2}
                  >
                    <Text
                      title="E-mail"
                      value={
                        loading ? (
                          <Skeleton animation="wave" />
                        ) : (
                          user?.userData.email
                        )
                      }
                    />
                    <Text
                      title="Celular"
                      value={
                        loading ? (
                          <Skeleton
                            animation="wave"
                            sx={{ width: 1 / 2 }}
                          />
                        ) : user?.userData.phone ? (
                          phoneFormat(user?.userData.phone ?? '')
                        ) : (
                          '-'
                        )
                      }
                    />
                    <Text
                      title="Data de Nascimento"
                      value={
                        loading ? (
                          <Skeleton
                            animation="wave"
                            sx={{ width: 1 / 2.5 }}
                          />
                        ) : user?.userData.dateBirth ? (
                          format(
                            parse(
                              user?.userData.dateBirth,
                              'yyyy-MM-dd',
                              new Date()
                            ),
                            'dd/MM/yyyy'
                          )
                        ) : (
                          '-'
                        )
                      }
                    />
                    <Text
                      title="CPF"
                      value={
                        loading ? (
                          <Skeleton
                            animation="wave"
                            sx={{ width: 1 / 2 }}
                          />
                        ) : user?.userData.cpf ? (
                          cpfFormat(user?.userData.cpf)
                        ) : (
                          '-'
                        )
                      }
                    />
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
            >
              <Stack spacing={2}>
                <Collapse
                  in={loading}
                  unmountOnExit
                >
                  <Stack spacing={2}>
                    {new Array(3).fill(null).map((_, key) => (
                      <Paper
                        key={key}
                        sx={{ p: 4 }}
                      >
                        <Skeleton
                          animation="wave"
                          height={40}
                        />
                        <Skeleton
                          animation="wave"
                          sx={{ width: 1 / 2 }}
                        />
                      </Paper>
                    ))}
                  </Stack>
                </Collapse>
                <Collapse
                  in={!loading}
                  unmountOnExit
                >
                  <Stack spacing={2}>
                    {(properties ?? []).map((property) => (
                      <Paper
                        key={property.id}
                        sx={{ p: 4 }}
                      >
                        <Stack spacing={2}>
                          <Stack
                            alignItems="center"
                            component="a"
                            direction="row"
                            justifyContent="space-between"
                            onClick={() => {
                              setExpanded(property.id);
                            }}
                            spacing={1}
                            sx={{
                              color: 'success.main',
                              fontSize: 14,
                              cursor:
                                properties.length > 1 ? 'pointer' : undefined,
                            }}
                          >
                            <LocationOn />
                            <Typography
                              component="span"
                              sx={{
                                flex: 1,
                              }}
                            >
                              Propriedade:{' '}
                              <Typography
                                component="span"
                                sx={{ color: 'grey.800' }}
                              >
                                {property.code}
                              </Typography>
                            </Typography>
                            {properties.length > 1 && (
                              <ExpandMore
                                sx={{
                                  color: 'grey.800',
                                  transform: `rotate(${
                                    expanded !== property.id ? -90 : 0
                                  }deg)`,
                                }}
                              />
                            )}
                          </Stack>
                          <Collapse
                            in={expanded === property.id}
                            key={property.id}
                            unmountOnExit
                          >
                            <Stack spacing={2}>
                              <Text
                                title="Área total (hectares)"
                                value={property.totalArea}
                              />
                              <Text
                                title="Área destinada a atividade leiteira (hectares)"
                                value={property.areaDairyActivity}
                              />
                              <Text
                                title="Tamanho do rebanho"
                                value={property.mediumHerdSize}
                              />
                              <Text
                                title="Tipo de produção"
                                value={property.mediumHerdSize}
                              />
                              <Text
                                title="CNPJ"
                                value={property.cnpj}
                              />
                              <Text
                                title="Nº inscrição produtor rural"
                                value={property.numberProducerRural}
                              />
                              <Text
                                title="Estado / Cidade"
                                value={`${property.state} - ${property.city}`}
                              />
                              <Text
                                title="País"
                                value={property.country}
                              />
                              <Text
                                title="Descrição de rota"
                                value={property.routeDescription}
                              />
                              <Divider />
                              <Typography
                                sx={{
                                  color: 'success.main',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <BusinessCenter sx={{ mr: 1 }} /> Responsáveis
                              </Typography>
                              <Text
                                title="Supervisor de Qualidade"
                                value={property.nameQualitySupervisor}
                              />
                              <Text
                                title="Supervisor de Compras"
                                value={property.namePurchasingSupervisor}
                              />
                            </Stack>
                          </Collapse>
                        </Stack>
                      </Paper>
                    ))}
                  </Stack>
                </Collapse>
              </Stack>
            </Grid>
            <Grid
              item
              md={4}
            >
              <Stack spacing={2}>
                <Paper sx={{ p: 3 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack direction="column">
                      <Typography
                        sx={{
                          color: 'success.main',
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Composição de Rebanho
                      </Typography>
                      <Typography sx={{ color: 'grey.500', fontSize: 12 }}>
                        {periodDescription}
                      </Typography>
                      <Stack direction="column">
                        {Object.keys(herdComposition ?? {}).length === 0 ? (
                          <EmptyChart />
                        ) : (
                          <HerdCompositionChart values={herdComposition} />
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper sx={{ p: 4 }}>
                  <Stack
                    direction="row"
                    gridTemplateColumns="1fr 1fr"
                    justifyContent="space-between"
                  >
                    <Stack direction="column">
                      <Typography
                        sx={{
                          color: 'success.main',
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Composição do Leite produzido
                      </Typography>
                      <Typography sx={{ color: 'grey.500', fontSize: 12 }}>
                        {periodDescription}
                      </Typography>
                      <Stack direction="column">
                        {!dataGraph ? (
                          <EmptyChart />
                        ) : (
                          <Stack gap={2}>
                            <Stack direction="column">
                              <Typography
                                sx={{ fontSize: 20, fontWeight: 'bold' }}
                              >
                                {loadingPrice ? (
                                  <Skeleton animation="wave" />
                                ) : (
                                  <>{numberFormat(dataGraph?.totalVolume)}</>
                                )}
                              </Typography>
                              <Typography
                                sx={{ color: 'grey.500', fontSize: 12 }}
                              >
                                litros/mês
                              </Typography>
                            </Stack>
                            <Stack direction="column">
                              <Typography
                                sx={{ fontSize: 20, fontWeight: 'bold' }}
                              >
                                {loadingPrice ? (
                                  <Skeleton animation="wave" />
                                ) : (
                                  // eslint-disable-next-line react/jsx-no-useless-fragment
                                  <>
                                    {dataGraph?.totalVolume
                                      ? Number(
                                          dataGraph.totalVolume /
                                            30 /
                                            (herdComposition?.female
                                              ?.lactatingCow?.value ?? 0)
                                        ).toFixed(2)
                                      : 0}
                                  </>
                                )}
                              </Typography>
                              <Typography
                                sx={{ color: 'grey.500', fontSize: 12 }}
                              >
                                litros/dia/vaca
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                    <Stack direction="column">
                      <Stack direction="column">
                        {
                          <Stack gap={2}>
                            {dataPie?.map((item: any) => (
                              <Stack direction="column">
                                <Stack
                                  direction="row"
                                  gap={1}
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Typography
                                    sx={{ color: 'grey.500', fontSize: 12 }}
                                  >
                                    {item?.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 'bold',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {loadingPrice ? (
                                      <Skeleton animation="wave" />
                                    ) : (
                                      // eslint-disable-next-line react/jsx-no-useless-fragment
                                      <>
                                        <Typography
                                          sx={{
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            width: 1 / 2,
                                          }}
                                        >
                                          {item?.value}L
                                        </Typography>
                                        {(
                                          ((item?.value ?? 0) /
                                            (dataGraph?.totalVolume ?? 0)) *
                                          100
                                        ).toFixed(2)}
                                        %
                                      </>
                                    )}
                                  </Typography>
                                </Stack>
                              </Stack>
                            ))}
                          </Stack>
                        }
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper sx={{ p: 3 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack direction="column">
                      <Typography
                        sx={{
                          color: 'success.main',
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Volume
                      </Typography>
                      <Typography sx={{ color: 'grey.500', fontSize: 12 }}>
                        {periodDescription}
                      </Typography>
                    </Stack>
                    <Stack direction="column">
                      <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                        {loadingVolume ? (
                          <Skeleton animation="wave" />
                        ) : (
                          numberFormat(volume, { minimumFractionDigits: 3 })
                        )}
                      </Typography>
                      <Typography sx={{ color: 'grey.500', fontSize: 12 }}>
                        Litros coletados
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper sx={{ p: 3 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack direction="column">
                      <Typography
                        sx={{
                          color: 'success.main',
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Preço
                      </Typography>
                      <Typography sx={{ color: 'grey.500', fontSize: 12 }}>
                        {price.lastResult
                          ? 'Últimos resultados'
                          : periodDescription}
                      </Typography>
                    </Stack>
                    <Stack direction="column">
                      <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                        {loadingPrice ? (
                          <Skeleton animation="wave" />
                        ) : (
                          <>R$ {numberFormat(price.total)}</>
                        )}
                      </Typography>
                      <Typography sx={{ color: 'grey.500', fontSize: 12 }}>
                        Por litro de leite
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper sx={{ p: 3 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack
                      direction="column"
                      sx={{ flex: 1 }}
                    >
                      <Typography
                        sx={{
                          color: 'success.main',
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Qualidade
                      </Typography>
                      <Typography sx={{ color: 'grey.500', fontSize: 12 }}>
                        Últimos resultados
                      </Typography>
                    </Stack>
                    <Stack
                      spacing={1}
                      sx={{ flex: 1 }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontSize: 12 }}>Gordura</Typography>
                        <Typography
                          sx={{
                            color: 'grey.800',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {loading || loadingQuality ? (
                            <Skeleton
                              animation="wave"
                              width={25}
                            />
                          ) : (
                            numberFormat(quality.fat)
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontSize: 12 }}>Proteína</Typography>
                        <Typography
                          sx={{
                            color: 'grey.800',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {loading || loadingQuality ? (
                            <Skeleton
                              animation="wave"
                              width={25}
                            />
                          ) : (
                            numberFormat(quality.prot)
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontSize: 12 }}>CBT</Typography>
                        <Typography
                          sx={{
                            color: 'grey.800',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {loading || loadingQuality ? (
                            <Skeleton
                              animation="wave"
                              width={25}
                            />
                          ) : (
                            numberFormat(quality.cbt)
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontSize: 12, color: 'grey.700' }}>
                          CCS
                        </Typography>
                        <Typography
                          sx={{
                            color: 'grey.500',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {loading || loadingQuality ? (
                            <Skeleton
                              animation="wave"
                              width={25}
                            />
                          ) : (
                            numberFormat(quality.ccs)
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontSize: 12 }}>EST</Typography>
                        <Typography
                          sx={{
                            color: 'grey.800',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {loading || loadingQuality ? (
                            <Skeleton
                              animation="wave"
                              width={25}
                            />
                          ) : (
                            numberFormat(quality.est)
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontSize: 12 }}>ESD</Typography>
                        <Typography
                          sx={{
                            color: 'grey.800',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {loading || loadingQuality ? (
                            <Skeleton
                              animation="wave"
                              width={25}
                            />
                          ) : (
                            numberFormat(quality.esd)
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        {/* </Collapse> */}
      </Content>
    </>
  );
};
export default UserProviderDetail;
