import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Chip,
  Collapse,
  Grid,
  Grow,
  Stack,
  Typography,
} from '@mui/material';
import { addHours, endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from 'recharts';

import { useAuth } from '../../../../contexts/auth';
import resources from '../../../../resources';
import { IVolumeDayOverview } from '../../../../resources/volume';
import { ChartBar } from '../chart-bar';
import ChartLine, { IChartLineValues } from '../chart-line';
import { LoadingChart } from '../loading-chart';

export interface IVolumeValueChart {
  name: string;
  value: number;
  date: Date;
}
interface IVolumeChart {
  values: IVolumeValueChart[];
  max: number;
  chip?: {
    label: string;
  };
  lactationCow: number;
  mediaPerDay: number;
}

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#94A352',
  '#FF8042',
  '#3B5E32',
  '#F0D591',
];

const renderActiveShape = (props: any, fill: string) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
      />
    </g>
  );
};

const MilkCompositionChart: React.FC<IVolumeChart> = ({
  values,
  chip,
  lactationCow,
  mediaPerDay,
}) => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [dayValues, setDayValues] = useState<
    IVolumeDayOverview['graphicDay'] | undefined
  >();

  const [loadingDayChart, setLoadingDayChart] = useState(false);
  const [dataGraph, setDataGraph] = useState<any | null>(null);

  const [milkPerDay, setMilkPerDay] = useState<number | null>(null);

  const [dataPie, setDataPie] = useState<any | null>(null);

  const dayChartValues = useMemo<IChartLineValues | undefined>(() => {
    if (!dayValues) return undefined;
    return {
      items:
        dayValues.map(({ date, totalVolume }: any) => ({
          name: format(addHours(parseISO(date), 3), 'dd/MMMM'),
          value: totalVolume,
        })) ?? [],
      max: 500,
    };
  }, [dayValues]);

  const fetchDataGraph = useCallback(async () => {
    const centers = searchParams.has('centers')
      ? searchParams.getAll('centers')
      : auth.user?.centers.map(({ id }) => id) ?? [];

    const startDate = searchParams.has('fromDate')
      ? new Date(searchParams.get('fromDate')!)
      : startOfMonth(new Date());

    const endDate = searchParams.has('toDate')
      ? new Date(searchParams.get('toDate')!)
      : endOfMonth(new Date());

    setLoadingDayChart(true);
    const response = await resources.use('volume').getVolumeByMilkComposition({
      codCenter: centers.join(','),
      fromDate: format(startDate, 'yyyy-MM-dd'),
      toDate: format(endDate, 'yyyy-MM-dd'),
    });

    if (response.status === 'OK') {
      setDataGraph(response?.payload?.data);

      if (response?.payload?.data) {
        const dataLocal: any = response?.payload?.data;

        setDataPie([
          {
            name: `litros para Amamentação`,
            value: Number(dataLocal?.calves?.value ?? 0),
          },
          {
            name: 'litros de Colostro',
            value: Number(dataLocal?.colostrum?.value ?? 0),
          },
          {
            name: 'litros Descarte',
            value: Number(dataLocal?.discardedMilk?.value ?? 0),
          },
          {
            name: 'litros em Uso Pessoal',
            value: Number(dataLocal?.internalConsumption?.value ?? 0),
          },
          {
            name: 'litros Outros Destinos',
            value: Number(dataLocal?.otherDestination?.value ?? 0),
          },
          {
            name: 'litros Vendidos',
            value: Number(dataLocal?.piracanjubaSale?.value ?? 0),
          },
        ]);
      } else {
        setDataPie(null);
      }
    } else {
      setDataGraph(null);
      setDataPie(null);
    }

    setLoadingDayChart(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.centers, searchParams]);

  useEffect(() => {
    fetchDataGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  console.log('teste dataGraph', dataPie);

  useEffect(() => {
    const volume = Number(dataGraph?.totalVolume ?? 0) / 30;
    const total = volume / mediaPerDay;
    setMilkPerDay(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph]);

  return (
    <Stack
      gridColumn={2}
      spacing={3}
    >
      <Grow
        in={!!chip?.label && !dayChartValues && !loadingDayChart}
        unmountOnExit
      >
        <Chip
          label={chip?.label}
          size="small"
          sx={{
            bgcolor: 'grey.700',
            color: 'white',
            alignSelf: 'center',
            position: 'relative',
          }}
        />
      </Grow>
      <Collapse
        in={loadingDayChart}
        unmountOnExit
      >
        <LoadingChart />
      </Collapse>
      <Grow in={!loadingDayChart && dataGraph && dataPie}>
        <Grid
          container
          gridTemplateColumns="1fr 1fr"
        >
          <Grid
            item
            width="20%"
          >
            <Box
              sx={{
                padding: 1,
                width: 200,
                borderRadius: 1,
              }}
            >
              <Grid container>
                <Grid item>
                  <Box
                    sx={{
                      bgcolor: '#f2f2f6',
                      padding: 3,
                    }}
                  >
                    <Typography
                      color="#1ba551"
                      component="h2"
                      marginBottom={2}
                      style={{ fontWeight: 'bold' }}
                      variant="h1"
                    >
                      Produtividade média de leite
                    </Typography>
                    <Typography
                      color="#034ea2"
                      component="h2"
                      variant="h1"
                    >
                      <span style={{ fontSize: 20, fontWeight: 'bold' }}>
                        {milkPerDay?.toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                        }) ?? 0}
                      </span>{' '}
                      litros/dia
                    </Typography>
                    <Typography
                      color="#4d4f5c"
                      fontSize={10}
                    >
                      por vacas em lactação
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    marginBottom={2}
                    sx={{
                      bgcolor: '#f2f2f6',
                      padding: 3,
                    }}
                  >
                    <Typography
                      color="#1ba551"
                      component="h2"
                      marginBottom={2}
                      style={{ fontWeight: 'bold' }}
                      variant="h1"
                    >
                      Vacas em lactação no rebanho
                    </Typography>
                    <Typography
                      color="#034ea2"
                      component="h2"
                      variant="h1"
                    >
                      <span style={{ fontSize: 20, fontWeight: 'bold' }}>
                        {lactationCow ? lactationCow.toFixed(0) : 0}
                      </span>{' '}
                      %
                    </Typography>
                    <Typography
                      color="#4d4f5c"
                      fontSize={10}
                    >
                      do total de animais
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            width="50%"
          >
            <Box
              height={270}
              width="100%"
            >
              <ResponsiveContainer
                height="100%"
                width="100%"
              >
                <PieChart
                  height={250}
                  width={200}
                >
                  <Pie
                    activeIndex={0}
                    activeShape={(props) =>
                      renderActiveShape(props, COLORS[0 % COLORS.length])
                    }
                    data={dataPie}
                    dataKey="value"
                    innerRadius={50}
                    label={({ value }) => {
                      const newValue = value
                        ? (value / (dataGraph?.totalVolume ?? 0)) * 100
                        : 0;
                      return value > 0 ? `${newValue?.toFixed(2)}%` : '';
                    }}
                    outerRadius={80}
                    paddingAngle={2}
                  >
                    {dataPie?.map((entry: any, index: number) => (
                      <Cell
                        fill={COLORS[index % COLORS.length]}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
          <Grid
            alignContent="center"
            item
            width="25%"
          >
            <Box>
              {dataPie?.map((data: any, index: number) => (
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  key={index}
                >
                  <Box
                    bgcolor={COLORS[index % COLORS.length]}
                    borderRadius="50%"
                    height={10}
                    width={10}
                  />
                  <Typography
                    fontSize={12}
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    <b>{data.value?.toFixed() ?? 0}</b> {data.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grow>
    </Stack>
  );
};

export default MilkCompositionChart;
